import Product from "./products";
import * as chrono from "chrono-node";
import moment from "moment";

function initDropdownSelects() {
  const styleStockSummary = data => {
    if (data.text == "") {
      // &nbsp;
      return "\u00A0";
    }

    if (!data.element) {
      return data.text;
    }

    const wrapperEl = document.createElement("span");
    wrapperEl.innerHTML = data.element.dataset.shortSummary + " ";
    const codeEl = document.createElement("span");
    codeEl.innerHTML = data.element.dataset.code;
    codeEl.classList.add("float-right");
    wrapperEl.appendChild(codeEl);

    return wrapperEl;
  };

  $(".dropdown-search-stock-summary").select2({
    allowClear: false,
    theme: "bootstrap",
    templateSelection: styleStockSummary,
    templateResult: styleStockSummary
  });

  $(".dropdown-search").select2({
    allowClear: false,
    theme: "bootstrap"
  });
}

function initProducts() {
  $(".product")
    .get()
    .forEach((node, i) => {
      new Product(node);
    });
}

function initEdgeModals() {
  // HACK: maybe combine this with the add/removeClass stuff in Edge
  $(".order-form").on("shown.bs.modal", ".modal-edge", function(e) {
    $(this)
      .find("select")[0]
      .focus();
    const id = $(this).attr("id");
    const button = $(`.btn-edge[data-target="#${id}"]`);
    const profile = $(this).find("select.edge-profile");

    profile.on("change", function() {
      const selectedOption = this.options[this.selectedIndex];
      const customizable = $(selectedOption).data("customizable");
      // TODO: to do the correct edge summary to match the one in
      // Rails EdgesHelper, need to have this all in Edge js class and use the
      // serialized form
      button.text(selectedOption.text);
      $(selectedOption)
        .closest(".modal-body")
        .find(".edge-customization")
        .toggleClass("invisible", !customizable);
    });

    profile.trigger("change");
  });
}

function initConfirmDelete() {
  $(".order-form").on("click", ".modal-delete-product .btn-ok", function(e) {
    $(this)
      .closest(".product")
      .addClass("d-none")
      .find("input.delete-product")
      .val(true);
  });
}

function initAddNewProduct() {
  $(".order-form").on("click", ".add_product", function(e) {
    // TODO: replace the id's so they're not duplicates like new_product_xxxx
    const time = new Date().getTime();
    const regexp = new RegExp($(this).data("id"), "g");
    const html = $(this)
      .data("fields")
      .replace(regexp, time);
    const node = $(html).insertAfter(
      $(this)
        .closest(".order-form")
        .find(".product")
        .last()
    );

    new Product(node);
    initDropdownSelects();

    const select = $(node).find("select")[0];
    select.focus();
    select.scrollIntoView();

    e.preventDefault();
  });
}

function initDisableReturnSubmit() {
  $(".order-form input:not(:submit), .order-form select").on("keypress", e => {
    return e.which !== 13;
  });
}

function initStatusNav() {
  $("body").on("click", ".nav-status a", function(e) {
    $("#products-datatable")
      .dataTable()
      .api()
      .ajax.url($(this).data("source"))
      .load();

    $(".nav-status a").removeClass("active");
    $(this).addClass("active");

    e.preventDefault();
  });
}

function initDatePicker() {
  $(".datepicker").each(function() {
    $(this).datepicker({
      uiLibrary: "bootstrap4",
      iconsLibrary: "fontawesome",
      format: "d mmm yyyy"
    });
  });

  // Any format for date fields

  $(".datepicker").on("blur", function(e) {
    const date = chrono.en_GB.parseDate($(this).val());
    const dateStr = date == null ? "" : moment(date).format("D MMM YYYY");
    $(this).val(dateStr);
  });
}

function initPrintProducts() {
  const printButtons = document.querySelectorAll(".btn-print");

  printButtons.forEach(button => {
    button.addEventListener("click", e => {
      const didClickLabel = button.classList.contains("btn-print-label");

      // If we're printing the label, hide the entire app container
      document
        .querySelector('body > div[class^="container"]')
        .classList.toggle("d-print-none", didClickLabel);
      document
        .querySelector("#product-print-page-label")
        .classList.toggle("d-print-block", didClickLabel);

      window.print();
      e.preventDefault();
    });
  });
}

$(document).on("turbolinks:load", () => {
  initProducts();
  initEdgeModals();
  initConfirmDelete();
  initAddNewProduct();
  initDisableReturnSubmit();
  initDropdownSelects();
  $("select#order_user_id").select2("focus");
  initStatusNav();
  initDatePicker();
  initPrintProducts();
});
