import * as Paper from "paper";
import Edge from "./edges";

export default class Product {
  edges = [];

  marginTop = 50;
  marginBottom = 50;
  marginLeftRight = 40;

  constructor(node) {
    this.node = node;
    this.data = $(node).data("product");

    this.paperScope = new Paper.PaperScope();
    const canvas = $(node).find(".product-preview canvas")[0];

    if (this.isEditable) {
      // Select2 uses jQuery, so we need to use jQuery here
      $(node)
        .find("input, select")
        .on("change", () => {
          this.getFormData();
          this.update();
        });
    } else {
      // For print layout
      // Ensure resize attribute on the HTML element, or the print preview triggers a resize
      // TODO: use this only for the print view
      canvas.width = 400;
      canvas.height = 400;
    }

    this.paperScope.setup(canvas);
    this.paperScope.view.onResize = e => this.update();

    this.update();
  }

  get isEditable() {
    return !!$(this.node).find("input").length;
  }

  getFormData() {
    // TODO: use FormData and vanilla JS
    const inputs = $(this.node).find("input, select");
    const attrs = inputs.serializeJSON().order.products_attributes;
    const formData = attrs[Object.keys(attrs)[0]];

    this.data.length = parseInt(formData.length);
    this.data.width = parseInt(formData.width);

    const stockNode = $(this.node).find(
      `.order_products_stock option[value="${formData.stock_id}"]`
    );

    if (stockNode) {
      this.data.stock = {
        length: stockNode.data("length"),
        width: stockNode.data("width"),
        thickness: stockNode.data("thickness"),
        short_summary: stockNode.data("shortSummary")
      };
    }

    const profileNodes = $(this.node)
      .find("select.edge-profile")
      .first();

    this.data.edges = Object.entries(formData.edges_attributes).map(
      ([i, attrs]) => {
        const data = {
          index: i,
          side: attrs.side,
          style: attrs.style_id
            ? {
                id: attrs.style_id,
                profile: profileNodes
                  .find(`option[value="${attrs.style_id}"]`)
                  .text()
              }
            : null
        };
        return data;
      }
    );
  }

  update() {
    if (this.data == "") {
      this.data = {};
      this.data.edges = [];
    }

    if (this.data.stock) {
      this.stockLength = this.data.stock.length;
      this.stockWidth = this.data.stock.width;
      this.stockSummary = this.data.stock.short_summary;
    }

    this.length = this.data.length;
    this.width = this.data.width;

    this.edges = this.data.edges.map(data => {
      return new Edge(this, data);
    });

    this.draw();

    if (!this.isEditable) {
      this.updateImage();
    }
  }

  get backgroundColor() {
    return "#eee";
  }

  get maxDrawHeight() {
    return (
      this.paperScope.view.size.height - this.marginTop - this.marginBottom
    );
  }

  get maxDrawWidth() {
    return this.paperScope.view.size.width - this.marginLeftRight * 2;
  }

  get maxDrawRect() {
    return new this.paperScope.Rectangle(
      [this.marginLeftRight, this.marginTop],
      [this.maxDrawWidth, this.maxDrawHeight]
    );
  }

  fitSizeInSize(inner, outer) {
    inner = new this.paperScope.Size(inner);
    outer = new this.paperScope.Size(outer);

    const size = new this.paperScope.Size(
      outer.width,
      inner.height * (outer.width / inner.width)
    );

    if (size.height > outer.height) {
      size.width = inner.width * (outer.height / inner.height);
      size.height = outer.height;
    }

    return size;
  }

  topCenterSizeInRect(inner, outer) {
    const rect = new this.paperScope.Rectangle(
      [outer.x + (outer.width - inner.width) / 2, outer.y],
      inner
    );

    return rect;
  }

  drawDimensions() {
    const dims = [
      [`${this.length}mm`, "bottomCenter", [0, 30], 0],
      [`${this.width}mm`, "leftCenter", [-15, 0], -90]
    ];

    dims.forEach(dim => {
      let text, point, offset, rotation;
      [text, point, offset, rotation] = dim;

      const label = new this.paperScope.PointText(this.rect[point].add(offset));
      label.fontSize = "18px";
      label.justification = "center";
      label.fillColor = "#000";
      label.rotation = rotation;
      label.content = text;
    });
  }

  drawStockLabel() {
    this.stockLabel = new this.paperScope.PointText(
      this.paperScope.view.bounds.topCenter.add([0, this.marginTop - 15])
    );
    this.stockLabel.fontSize = "18px";
    this.stockLabel.justification = "center";
    this.stockLabel.fillColor = "#000";
    this.stockLabel.content = this.stockSummary || "";
  }

  drawEdgeLabels() {
    const labels = {
      front: { point: "bottomCenter", offset: [0, -40], rotation: 0 },
      left: { point: "leftCenter", offset: [50, 0], rotation: -90 },
      back: { point: "topCenter", offset: [0, 50], rotation: 0 },
      right: { point: "rightCenter", offset: [-50, 0], rotation: 90 }
    };

    Object.entries(labels).forEach(([side, item]) => {
      const label = new this.paperScope.PointText(
        this.rect[item.point].add(item.offset)
      );
      label.justification = "center";
      label.fillColor = "#000";
      label.rotation = item.rotation;
      label.content = side.charAt(0).toUpperCase() + side.slice(1);
    });
  }

  drawStock() {
    const size = this.fitSizeInSize(
      [this.stockLength, this.stockWidth],
      this.maxDrawRect
    );

    this.stockRect = this.topCenterSizeInRect(
      size,
      this.paperScope.view.bounds
    );

    const stockPath = new this.paperScope.Path.Rectangle(this.stockRect);
    stockPath.fillColor = "#fff";
    stockPath.strokeColor = "#ddd";
  }

  productRectInStockRect(stockRect) {
    const scale = stockRect.height / this.stockWidth;
    const size = new this.paperScope.Size([
      this.length * scale,
      this.width * scale
    ]);

    const point = stockRect.point.add([0, stockRect.height - size.height]);
    return new this.paperScope.Rectangle(point, size);
  }

  productRectFullSize() {
    const size = this.fitSizeInSize(
      [this.length, this.width],
      this.maxDrawRect
    );

    const rect = this.topCenterSizeInRect(size, this.maxDrawRect);

    // Constrain to a minimum width/height so text is always readable
    if (rect.height < 110) {
      rect.height = 110;
    }
    if (rect.width < 110) {
      rect.width = 110;
    }

    return rect;
  }

  drawProduct() {
    this.rect = this.productRectFullSize();

    const productPath = new this.paperScope.Path.Rectangle(this.rect);
    productPath.fillColor = this.backgroundColor;
    productPath.strokeColor = "#000";
  }

  drawThumbnail() {
    const stockThumbSize = new this.paperScope.Size([
      this.stockLength * (25 / this.stockWidth),
      25
    ]);

    const stockThumbRect = new this.paperScope.Rectangle({
      point: [
        12,
        this.paperScope.view.bounds.height - stockThumbSize.height - 12
      ],
      size: stockThumbSize
    });

    const stockThumbPath = new this.paperScope.Path.Rectangle(stockThumbRect);

    stockThumbPath.fillColor = "#eee";
    stockThumbPath.strokeColor = "#aaa";

    const thumbRect = this.productRectInStockRect(stockThumbRect);
    const thumbRectPath = new this.paperScope.Path.Rectangle(thumbRect);
    thumbRectPath.fillColor = "#ddd";
    thumbRectPath.strokeColor = "#aaa";

    const thumbRectX1 = new this.paperScope.Path.Line(
      thumbRect.topLeft,
      thumbRect.bottomRight
    );
    const thumbRectX2 = new this.paperScope.Path.Line(
      thumbRect.topRight,
      thumbRect.bottomLeft
    );
    thumbRectX1.strokeColor = "#aaa";
    thumbRectX2.strokeColor = "#aaa";
  }

  draw() {
    this.paperScope.activate();
    this.paperScope.project.activeLayer.removeChildren();

    this.drawStockLabel();

    if (!this.length || !this.width) {
      return;
    }

    this.drawProduct();
    this.drawDimensions();
    this.edges.forEach(edge => edge.draw());
    this.drawEdgeLabels();
    this.drawThumbnail();

    this.paperScope.view.draw();
  }

  toDataURL() {
    const id = this.paperScope.view._id;
    const canvas = document.getElementById(id);
    return canvas.toDataURL();
  }

  updateImage() {
    let image = this.node.querySelector(".product-preview-image");
    if (image) image.remove();

    image = new Image(
      this.paperScope.view.size.width,
      this.paperScope.view.size.height
    );
    image.className = "product-preview-image";
    image.src = this.toDataURL();

    this.node.querySelector(".product-preview").prepend(image);
  }
}
