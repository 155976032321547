import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["checkboxAll", "checkbox"];

  initialize() {
    this.toggleAll = this.toggleAll.bind(this);
  }

  connect() {
    this.checkboxAllTarget.addEventListener("change", this.toggleAll);
    this.update();
  }

  update(e) {
    const checkedIds = this.checkboxTargets
      .filter(checkbox => {
        return checkbox.checked;
      })
      .map(checkbox => {
        return checkbox.value;
      })
      .join(",");

    const button = document.querySelector("#btn-xlsx-export-container a");
    if (button) {
      const url = new URL(button.href);
      url.searchParams.set("ids", checkedIds);
      button.href = url;
      button.classList.toggle("disabled", checkedIds == "");
    }
  }

  toggleAll(e) {
    this.checkboxTargets.forEach(checkbox => {
      checkbox.checked = e.target.checked;
    });
    this.update();
  }
}
