import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["input", "button", "item"];

  connect() {
    this.productContainer = this.element.closest(".product");
    this.productData = JSON.parse(this.productContainer.dataset.product);
  }

  updateStatus(event) {
    this.status = event.target.dataset.status;
    this.inputTarget.value = this.status;
    this.buttonTarget.innerText = event.target.innerText;

    this.updateStyles();
    this.toggleEnabledFields();
    event.preventDefault();
  }

  updateStyles() {
    const statusClasses = {
      unapproved: "warning",
      approved_for_production: "warning",
      picked_for_production: "warning",
      in_production: "warning",
      complete: "success",
      finalised: "success",
      cancelled: "secondary"
    };

    const outlineClasses = Object.values(statusClasses).map(s => {
      return `btn-outline-${s}`;
    });

    this.buttonTarget.classList.remove(...outlineClasses);
    this.buttonTarget.classList.add(
      `btn-outline-${statusClasses[this.status]}`
    );

    this.itemTargets.forEach((item, i) => {
      item.classList.toggle("active", item == event.target);
    });
  }

  // Disable fields based on product status

  toggleEnabledFields() {
    const enabledStatuses = ["complete", "finalised", "cancelled"];
    const enabled = enabledStatuses.includes(this.status);

    const fields = this.productContainer.querySelectorAll(
      ".order_products_stock_quantity_actual, .order_products_finished_on_friendly"
    );

    for (let field of fields) {
      const inputs = field.querySelectorAll("input");
      // TODO: disable button's ability to be clicked
      // and ensure it's disabled on page load
      const children = field.querySelectorAll("label, button");

      for (var input of inputs) {
        input.disabled = !enabled;
      }

      const elementsToAddClass = new Set([field, ...inputs, ...children]);
      elementsToAddClass.forEach((el, i) => {
        el.classList.toggle("disabled", !enabled);
      });
    }
  }
}
