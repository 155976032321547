// Defaults

$.fn.dataTable.ext.errMode = "throw";
$.extend(true, $.fn.dataTable.defaults, {
  dom:
    "<'row'<'col-6'l><'col-6'f>>" +
    "<'row'<'col-12'tr>>" +
    "<'row'<'col-5'i><'col-12 col-7'p>>",
  renderer: "bootstrap",
  autoWidth: false,
  deferRender: true,
  info: true,
  paginate: true,
  lengthMenu: [100, 200, 1000],
  lengthChange: true,
  pageLength: 100,
  ordering: true,
  orderMulti: true,
  orderClasses: true,
  paging: true,
  scrollX: false,
  scrollY: false,
  searching: true,
  stateSave: false,
  processing: true,
  serverSide: true,
  pagingType: "full_numbers",
  language: {
    processing: "Loading...",
    loadingRecords: "Loading..."
  }
});

// Tables and options

const dataTables = {
  "#stocks-datatable": {
    language: {
      emptyTable: "No stock"
    },
    order: [[1, "asc"]],
    columns: [
      {
        data: "code",
        render: {
          _: "display",
          sort: "sort_by"
        },
        className: "code text-left",
        width: "2.5rem"
      },
      { data: "color", className: "color text-left", width: "3rem" },
      { data: "medium", className: "medium text-left", width: "3rem" },
      {
        data: "factory_edge",
        className: "factory_edge text-left",
        width: "5rem"
      },
      { data: "length", className: "length text-center", width: "0.7rem" },
      { data: "width", className: "width text-center", width: "0.7rem" },
      { data: "thickness", className: "thickness text-center", width: "1rem" },
      { data: "sap_value", className: "sap_value text-center", width: "1rem" },
      {
        data: "include_in_list_stock",
        className: "include_in_list_stock text-center",
        width: "1rem"
      },
      {
        data: "include_in_list_bom",
        className: "include_in_list_bom text-center",
        width: "1rem"
      }
    ]
  },

  "#contacts-datatable": {
    language: {
      emptyTable: "No contacts"
    },
    order: [[0, "asc"]],
    columns: [
      {
        data: "company_name",
        render: {
          _: "display",
          sort: "sort_by"
        },
        className: "text-left",
        width: "24rem"
      },
      { data: "code", className: "text-center", width: "4rem" },
      {
        data: "email",
        className: "text-left",
        width: "24rem",
        orderable: false
      },
      { data: "orders_count", className: "text-center", width: "4rem" }
    ]
  },

  "#products-datatable": {
    xlsxExport: true,
    language: {
      emptyTable: "No orders"
    },
    order: [[2, "desc"]],
    createdRow: function(row, data, dataIndex) {
      const template = document.querySelector("template#checkbox");
      const clone = template.content.cloneNode(true).querySelector("input");
      clone.value = data.DT_RowId;
      row.firstChild.appendChild(clone);
    },
    columns: [
      {
        render: () => {
          return "";
        },
        width: "1rem",
        orderable: false
      },
      { data: "status", className: "text-left", width: "7.5rem" },
      {
        data: "entered_date",
        render: {
          _: "display",
          sort: "sort_by"
        },
        className: "text-right",
        width: "3.5rem"
      },
      {
        data: "sales_order_number",
        render: {
          _: "display",
          sort: "sort_by"
        },
        className: "text-center",
        width: "4rem"
      },
      { data: "contact", className: "text-left", width: "10rem" },
      {
        data: "complete_date",
        render: {
          _: "display",
          sort: "sort_by"
        },
        className: "text-right",
        width: "5rem"
      },
      {
        data: "finished_on",
        render: {
          _: "display",
          sort: "sort_by"
        },
        className: "text-right",
        width: "3.5rem"
      },
      {
        data: "despatch_date",
        render: {
          _: "display",
          sort: "sort_by"
        },
        className: "text-right",
        width: "5rem"
      },
      { data: "stock_color", className: "text-left", width: "6rem" },
      {
        data: "length_width",
        className: "text-center",
        width: "3rem"
      },
      { data: "profiles", className: "text-left", width: "13rem" },
      { data: "quantity", className: "text-right", width: "auto" },
      { data: "sales_person", className: "text-left", width: "3rem" },
      { data: "work_order_number", className: "text-center", width: "3rem" }
    ]
  }
};

function initDataTables() {
  for (var selector in dataTables) {
    if ($.isEmptyObject($.find(selector))) {
      continue;
    }

    const options = dataTables[selector];
    options.ajax = { url: $(selector).data("source") };

    // Add XLSX container

    if (options.xlsxExport) {
      options.dom =
        "<'row'<'col-6'<'#btn-xlsx-export-container'>l><'col-6'f>>" +
        "<'row'<'col-12'tr>>" +
        "<'row'<'col-5'i><'col-12 col-7'p>>";
    }

    // Ensure datatables aren't initialized multiple times via back button

    let table;
    if ($.fn.DataTable.isDataTable(selector)) {
      table = $(selector).DataTable();
    } else {
      table = $(selector).DataTable(options);
    }
    $(document).on("turbolinks:before-cache", () => {
      table.destroy();
    });

    // Add XLSX export button

    const template = document.querySelector("template#btn-xlsx-export");
    const clone = template.content.cloneNode(true).querySelector("a");
    document.querySelector("#btn-xlsx-export-container").appendChild(clone);
  }
}

function initTableCellTooltips() {
  // Tooltips for truncated cells

  $(".table-tooltip-truncated").on("mouseenter", "tbody td", function() {
    const $this = $(this);
    if (this.offsetWidth < this.scrollWidth && !$this.attr("title")) {
      $this.tooltip({
        title: $this.text(),
        placement: "bottom"
      });
      $this.tooltip("show");
    }
  });
}

$(document).on("turbolinks:load", () => {
  initDataTables();
  initTableCellTooltips();
});
