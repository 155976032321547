export default class Edge {
  constructor(product, data) {
    this.product = product;
    this.data = data;
  }

  static sides = ["front", "left", "back", "right"];

  get index() {
    return this.data.index;
  }

  get exists() {
    return !!this.data.style;
  }

  get backgroundColor() {
    return this.exists ? "#ccc" : this.product.backgroundColor;
  }

  get labelColor() {
    return this.exists ? "#000" : "#999";
  }

  get summary() {
    return this.exists ? this.data.style.profile : "None";
  }

  drawLabel() {
    let point, offset, rotation;
    [point, offset, rotation] = [
      ["bottomCenter", [0, -11], 0],
      ["leftCenter", [18, 0], -90],
      ["topCenter", [0, 18], 0],
      ["rightCenter", [-18, 0], 90]
    ][this.index];

    const label = new this.product.paperScope.PointText(
      this.product.rect[point].add(offset)
    );
    label.justification = "center";
    label.fillColor = this.labelColor;
    label.rotation = rotation;
    label.content = this.summary;
  }

  drawArea() {
    let pointA, pointB;
    [pointA, pointB] = [
      ["bottomRight", "bottomLeft"],
      ["bottomLeft", "topLeft"],
      ["topLeft", "topRight"],
      ["topRight", "bottomRight"]
    ][this.index];

    const innerRect = this.product.rect.expand(-60);
    const pathSegments = [
      this.product.rect[pointA],
      this.product.rect[pointB],
      innerRect[pointB],
      innerRect[pointA]
    ];

    this.path = new this.product.paperScope.Path(pathSegments);
    this.path.strokeColor = "#000";
    this.path.fillColor = this.backgroundColor;
    this.path.closed = true;
  }

  doHitTest() {
    const hitTestPath = new this.product.paperScope.Path(this.path.segments);
    hitTestPath.fillColor = "rgba(0,0,0,0.01)";
    hitTestPath.on("mousedown", e => {
      this.button.trigger("click");
    });
    hitTestPath.on("mouseenter", e => {
      this.path.fillColor = "#fff";
    });
    hitTestPath.on("mouseleave", e => {
      this.path.fillColor = this.backgroundColor;
    });
  }

  get button() {
    return $(this.product.node)
      .find(".btn-edge")
      .eq(this.index);
  }

  doButtonStyle() {
    this.button.toggleClass("btn-outline-primary", this.exists);
    this.button.toggleClass("btn-outline-secondary", !this.exists);
  }

  draw() {
    this.drawArea();
    this.drawLabel();

    if (this.product.isEditable) {
      this.doHitTest();
      this.doButtonStyle();
    }
  }
}
