require("@rails/ujs").start();
require("turbolinks").start();
require("@rails/activestorage").start();
require("channels");

import "controllers";
import $ from "jquery";
import "bootstrap/dist/js/bootstrap";
import "jquery-serializejson";
import "select2";
import "gijgo";

import "datatables.net";
import "./lib/dataTables.bootstrap4";

$(document).on("turbolinks:load", function() {
  $("body").tooltip({
    selector: '[data-toggle="tooltip"]',
    container: "body"
  });

  $("body").popover({
    selector: '[data-toggle="popover"]',
    container: "body",
    html: true,
    trigger: "hover"
  });
});

import "./orders";
import "./datatables";
